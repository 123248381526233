.header-container {
    width          : 80%;
    height         : 100%;
    display        : flex;
    align-items    : center;
    // position   : relative;
    //  z-index    : 3 !important;

    .btn-menu-drawer {
        // background-color: blue;
        display        : flex;
        justify-content: center;
        align-items    : center;
        width          : 40px;
        height         : 100%;
        padding-right  : 5px;

        svg {
            font-size: 22px !important;
        }

        .ant-btn {
            background-color: transparent !important;
            border-width    : 0px !important;
            color           : rgba(0, 0, 0, .54);
        }
    }

    .header-logo-container {
        display           : flex;
        justify-content   : flex-start;
        //background-color: brown;

        .header-logo {
            min-width      : 180px;
            width          : 180px;
            height         : 100%;
            display        : flex;
            align-items    : center;
            justify-content: center;
        }
    }

    .header-menu-items {
        display     : flex !important;
        flex        : 1 !important;
        padding-left: 30px;

        .ant-menu {
            background-color: transparent;
            height          : 55px;
            display         : flex !important;
            flex            : 1 !important;
            justify-content : flex-start !important;
        }

        .ant-menu-item-selected {
            color: black !important;
        }

        .ant-menu-item-selected::after {
            border-bottom-width: 5px !important;
            border-radius      : 10px;
            max-width          : 50%;
            margin-left        : 18px !important;
        }

        .ant-menu-item-active::after {
            border-bottom-width: 5px !important;
            border-radius      : 5px;
            max-width          : 50%;
            margin-left        : 18px !important;
        }
    }

    .header-auth {
        margin-left    : auto;
        display        : flex;
        justify-content: flex-end;
        align-items    : center;
        gap            : 4px;

        .btn-login {
            height          : 36px;
            width           : 77px;
            border-radius   : 36px;
            text-transform  : uppercase;
            font-size       : 14px;
            font-weight     : 700;
            border-width    : 0;
            background-color: transparent;
            margin          : 0px 4px;
            padding         : 0px 4px;
        }

        .btn-register-logout {
            height        : 44px;
            width         : 115px;
            border-radius : 36px;
            text-transform: uppercase;
            font-size     : 14px;
            font-weight   : 700;
            margin        : 0px 8px;
            padding       : 8px 24px;
        }
    }

    .header-config {
        height         : 100%;
        display        : flex;
        align-items    : center;
        justify-content: center;
        padding-right  : 5px;

        .border-divid {
            height: 50%;
            width : 3 px;
            border: 1px solid rgba(0, 0, 0, 0.199);
        }

        svg {
            font-size: 22px !important;
        }

        .ant-btn {
            background-color: transparent !important;
            border-width    : 0px !important;
            color           : rgba(0, 0, 0, .54);
        }
    }

}

.ant-drawer {
    // position: absolute;
    z-index: 0 !important;
}


@media screen and (min-width : 1280px) {
    .btn-menu-drawer {
        display: none !important;
    }

}

@media screen and (max-width: 1280px) {
    .header-container {
        width: 90%;
    }

}


@media screen and (max-width: 960px) {
    .header-container {
        width: 100%;
    }

    .header-logo-container {
        flex           : 1;
        justify-content: center !important;
    }

    .header-auth {
        display: none !important;
    }

}

/* .header-logo {
    min-width       : 200px;
    width           : 200px;
    height          : 100%;
    display         : flex;
    align-items     : center;
    justify-content : center;
}

.header-menu-items {
    flex            : 1;
    display         : flex;
    justify-content : center;
    align-items     : center;
    padding         : 10px;
    .btn-menu {
        svg {
            font-size: 22px;
        }
    }
   
}


@media (max-width: 1200px) {
    .header-container {
        width: 85%;
    }
}

@media (max-width: 1000px) {
    .header-container {
        width: 90%;
    }
}

@media (max-width: 800px) {
    .header-container {
        width: 95%;
    }
}

@media (max-width: 600px) {
    .header-container {
        width: 100%;
    }
} */