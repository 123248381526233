:root {
    --bodyFontDefault : 'Cairo', sans-serif;
    --footerHeight : 50px;
    --headerMenuHeight : 80px;
    --headerInfosHeight : 40px;
    --contentHeight : calc((100vh - 30px) - (var(--headerInfosHeight) + var(--headerMenuHeight)));
    --marginMenuWithinfoBar : calc(var(--headerInfosHeight) + var(--headerMenuHeight));
    --marginInfosBar : var(--headerInfosHeight);
    --marginMenu : var(--headerMenuHeight);
    --marginWithoutMenuInfoBar : 0px;
    --clr : red;
    --borderContainerColor : rgb(0, 153, 255);
}
