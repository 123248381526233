.container-title {
    text-align       : center;
    padding-bottom   : 30px;
    scroll-margin-top: calc((var(--headerInfosHeight) + var(--headerMenuHeight)) - 40px);
}
.container-title-second {
    text-align       : center;
    
 
}
.container-title-form {
    text-align       : center;
    padding-bottom   : 10px;
}


.ligne-title-finish {
    content      : '';
    width        : 100%;
    height       : 3px;
    // background: #1a5dac;
    bottom       : 0;
    margin-top   : 10px;
}

.section-title {


    text-transform: uppercase;
    margin-bottom : 20px;

    text-align    : center;
    display       : flex;
    flex-direction: column;
    align-items   : center;

    &.form {
        margin-bottom: 0px;
    }

    h2 {
        font-size       : 26px !important;
        font-weight     : 600;
        margin-block-end: 0.33em;
    }

    .ligne-title {
        content      : '';
        width        : 50px;
        height       : 3px;
        // background: #1a5dac;
        bottom       : 0;
    }


}
.section-title-second {


    text-transform: uppercase;
    margin-bottom : 0px;

    text-align    : center;
    display       : flex;
    flex-direction: column;
    align-items   : center;

    &.form {
        margin-bottom: 0px;
    }

    h2 {
        font-size       : 18px !important;
        font-weight     : 700;
        
    }

    .ligne-title {
        content      : '';
        width        : 50px;
        height       : 3px;
        // background: #1a5dac;
        bottom       : 0;
    }


}