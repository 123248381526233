body {
    font-family            : var(--bodyFontDefault);
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-family            : var(--bodyFontDefault);
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale; 
}