.ant-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Assure que le layout occupe au moins toute la hauteur de la fenêtre */
}

.ant-layout-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-around;
  //padding-top: var(--headerMenuHeight); /* S'assure que le contenu ne passe pas sous le header */
  //padding-bottom: var(--footerHeight); /* S'assure que le contenu ne passe pas sous le footer */
}

.ant-layout-header{
  padding: 0px;

}
.header-infos {
  height: var(--headerInfosHeight) !important;
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}

.header-menu {
  background-color: rgba(234, 245, 253) !important;
  transition: all 0.5s;
  height: var(--headerMenuHeight) !important;
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  opacity: 1;
  transition: opacity 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}

.ant-layout-footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px !important;
  height: var(--footerHeight);
  text-align: center !important;
  font-weight: 600 !important;
 // position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-container {
  width          : 100%;
  height         : var(--headerInfosHeight);
  display        : flex;
  align-items    : center;
  justify-content: center;
  text-align     : center;
  transition     : opacity 1s ease-out, transform 1s ease-out;
}

.line {
  height         : var(--headerInfosHeight);
  font-size      : 16px;
  font-weight    : bold;
  display        : inline-flex;
  align-items    : center;
  justify-content: center;
  text-align     : center;
  text-align     : center;
}

.non-mobile .text-container {
  width: 50%;
}

.mobile .text-container {
  width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.2);
    opacity  : 0;
  }

  60% {
    transform: scale(1.1);
    opacity  : 1;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rotateIn {
  0% {
    transform: rotateZ(-90deg) scale(0.2);
    opacity  : 0;
  }

  100% {
    transform: rotateZ(0) scale(1);
    opacity  : 1;
  }
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

@keyframes fadeInFromLeft {
  0% {
    opacity  : 0;
    transform: translateX(-20px);
  }

  100% {
    opacity  : 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity  : 0;
    transform: translateX(20px);
  }

  100% {
    opacity  : 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromTop {
  0% {
    opacity  : 0;
    transform: translateY(-20px);
  }

  100% {
    opacity  : 1;
    transform: translateY(0);
  }
}

@keyframes fadeInFromBottom {
  0% {
    opacity  : 0;
    transform: translateY(20px);
  }

  100% {
    opacity  : 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity  : 0;
  }

  100% {
    transform: scale(1);
    opacity  : 1;
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.animate-fade-in {
  animation: fadeIn 1s linear forwards;
}

.animate-fade-out {
  animation: fadeOut 1s linear forwards;
}

.animate-bounce-in {
  animation: bounceIn 1s ease-out forwards;
}

.animate-rotate-in {
  animation: rotateIn 1s ease-out forwards;
}

.animate-shake {
  animation: shake 0.5s ease-out forwards;
}

.animate-fade-in-from-left {
  animation: fadeInFromLeft 1s ease-out forwards;
}

.animate-fade-in-from-right {
  animation: fadeInFromRight 1s ease-out forwards;
}

.animate-fade-in-from-top {
  animation: fadeInFromTop 1s ease-out forwards;
}

.animate-fade-in-from-Bottom {
  animation: fadeInFromBottom 1s ease-out forwards;
}

.animate-zoom-in {
  animation: zoomIn 1s ease-out forwards;
}

.animate-bounce {
  animation: bounce 1s ease-out forwards;
}

.container-main-landing-full {
  width : 100%;
  height: 350px;

}

.container-main-landing {
  width          : 100%;
  height         : 100% !important;
  display        : flex;
  align-content  : center;
  justify-content: space-between;
  padding-right  : 10px;
  padding-left   : 10px;
  margin-right   : auto;
  margin-left    : auto;


}


@media (min-width: 576px) {
  .container-main-landing {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-main-landing {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-main-landing {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-main-landing {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-main-landing {
    max-width: 1320px;
  }
}

.container-menu {
  //  display: flex;
  //  justify-content: flex-end;
  height: calc(var(--headerMenuHeight) - 5px);
  width : 100%;



  @media (max-width : 768px) {
    width: 95%;
  }

  .ant-menu-light {
    background-color: transparent !important;
  }



  .btn-menu {
    height         : calc(var(--headerMenuHeight) - 5px);
    display        : flex;
    justify-content: flex-end;
    align-items    : center;

    svg {
      font-size: 22px;
    }
  }

  @media (max-width : 1109px) {
    .header-menu-menu {
      display: none;
    }
  }

  @media (min-width : 1110px) {
    .btn-menu {
      display: none;
    }
  }

}

.container-main-landing-menu {
  width  : 70%;
  height : 100% !important;
  display: flex;

  align-content  : center;
  justify-content: space-between;
  padding-right  : 10px;
  padding-left   : 10px;
  margin-right   : auto;
  margin-left    : auto;

  @media (max-width: 1109px) {
    width: 100%;
  }
}

.menu-drawer {

  height: 100vh !important;
  top   : 0 !important;

  .ant-drawer-body {
    display        : flex !important;
    flex-direction : column !important;
    align-content  : center;
    justify-items  : center;
    justify-content: center !important;
    align-items    : center !important;
    //text-align: center;
  }

  .ant-drawer-title {
    display        : flex;
    justify-content: center;
  }

  .ant-menu {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    text-align    : center;
    font-size     : 18px;

  }

}

.container-logo {
  display: flex;

  align-items   : center;
  height        : calc(var(--headerMenuHeight) - 5px);
  // min-width: 200px;
  // width      : 100%;
}

.logo {
  font-size        : 28px;
  margin           : 0;
  padding          : 0;
  // line-height   : 1;
  //font-weight   : 600;
  //letter-spacing: 0.5px;
  // text-transform: uppercase;

  .logo-picture {
    max-height   : 65px;
    width        : 100%;
    //  min-width: 140px !important;
  }
}

.ant-menu-title-content {
  font-size  : 15px;
  font-weight: 700;
  //color : #9effbb

}

.ant-menu-horizontal .ant-menu-item {
  padding-inline: 10px !important;
}

.container-main-small {
  width        : 100%;
  height       : 100% !important;
  padding-right: 10px;
  padding-left : 10px;
  margin-right : auto;
  margin-left  : auto;

}

.container-main-vsmall {
  width        : 100%;
  height       : 100% !important;
  padding-right: 10px;
  padding-left : 10px;
  margin-right : auto;
  margin-left  : auto;
}



@media (min-width: 576px) {
  .container-main-small {
    max-width: 540px;
  }
  .container-main-vsmall {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-main-small {
    max-width: 720px;
  }
  .container-main-vsmall {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-main-small {
    max-width: 960px;
  }
  .container-main-vsmall {
    max-width: 900px;
  }
}

@media (min-width: 1200px) {
  .container-main-small {
    max-width: 1140px;
  }
  .container-main-vsmall {
    max-width: 900px;
  }
}

@media (min-width: 1400px) {
  .container-main-small {
    max-width: 1320px;
  }
  .container-main-vsmall {
    max-width: 900px;
  }
}