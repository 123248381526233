.container-main {
    width        : 100%;
    height       : 100% !important;
    padding-right: 10px;
    padding-left : 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right : auto;
    margin-left  : auto;
 //   max-width    : 1320px;

}

/* @media (min-width: 576px) {
    .container-main {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container-main {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container-main {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container-main {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container-main {
        max-width: 1320px;
    }
} */

.container-second {

    background-color  : rgba(255, 255, 255, 0.637);
    color             : rgb(0, 40, 92);
    font-weight       : bold;
    box-sizing        : border-box;
    padding           : 10px;
  
  
    border            : 2px solid #ffffff;
    border-radius     : 11px;
    border-color      : var(--borderContainerColor);
    box-shadow        : 1px -1px 15px 10px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 1px -1px 15px 10px rgba(0, 0, 0, 0.15);
    -moz-box-shadow   : 1px -1px 15px 10px rgba(0, 0, 0, 0.15);

    .login-form-forgot {
        float: right;
    }

    .ant-col-rtl .login-form-forgot {
        float: left;
    }

    .login-form-button {
        width: 100%;
    }


    &.login {
        max-width: 600px;
        margin   : auto;
    }


    &.register {
        max-width    : 1100px;
        margin       : auto;
        margin-bottom: 20px;
        padding: 0px;
        padding-top: 10px;
    }

    &.large {
        width: 95%;
        margin   : auto;
        //height: 100% !important;

    }
    &.small {
        width: 50%;
        margin   : auto;
        //height: 100% !important;

    }
    
}

.title-back {
    display: flex;
}

.conteneur-flex {
    display        : flex;
    flex-flow      : row wrap;
    justify-content: space-around;


}

.conteneur-flex-center {
    width  : 100% !important;
    display: flex;

    flex-flow         : row wrap;
    justify-content   : space-around;
    // justify-content: center;
    justify-items     : center;
    align-items       : center;
    align-content     : center;

    .ant-radio-group {
        display        : flex;
        flex-flow      : row wrap;
        justify-content: space-around;

    }
}

.element-flex {
    flex: auto;
}